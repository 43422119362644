import React from "react"
import styled from "styled-components"

const Background = styled.div`
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 4%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
  padding: 8rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  text-align: center;
`

let PageTitle = ({ title }) => (
  <Background>
    <h1>{title}</h1>
  </Background>
)

export default PageTitle
