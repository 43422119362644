import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Grid } from "@material-ui/core"

import Img from "gatsby-image"

import LightboxReact from "lightbox-react"
import "lightbox-react/style.css"
import ThumbGrid from "../components/thumbnails"
import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import styled from "styled-components"

const Container = styled.div`
  padding: 50px;
`
const Lightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const array = []

  images.forEach((image) =>
    array.push(<NonStretchedImage fluid={image.node.childImageSharp.fluid} />)
  )

  return (
    <LightboxReact
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  )
}

const NonStretchedImage = (props) => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        // maxHeight: window.innerHeight - 50px,
        height: window.innerHeight - 50,
        width: "auto",
        margin: "50px auto 0", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

const GalleryComponent = (props) => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = (i) => (e) => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => (e) => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => (e) => {
    setSelectedImage((i + 1) % length)
  }
  return (
    <StaticQuery
      query={graphql`
        query allImgQuery {
          source: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxHeight: 500) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const images = data.source.edges
        return (
          <Grid container spacing={5} justify="center">
            <ThumbGrid images={images} handleOpen={handleOpen} />
            {showLightbox && selectedImage !== null && (
              <Lightbox
                images={images}
                handleClose={handleClose}
                handleNextRequest={handleNextRequest}
                handlePrevRequest={handlePrevRequest}
                selectedImage={selectedImage}
              />
            )}
          </Grid>
        )
      }}
    />
  )
}

let Works = () => {
  return (
    <Layout>
      <PageTitle title="TRAVAUX" />
      <Container>
        <GalleryComponent />
      </Container>
    </Layout>
  )
}

export default Works
